<template>
    <section>
        <div class="row mx-0 my-3">
            <div class="col-auto text-general f-600 f-18">
                Lista Equipos
            </div>
            <div class="col-auto ml-auto px-2">
                <div v-if="$can('botones_equipos_gestionar')" class="btn-general f-14 px-3" @click="crearEquipo()">
                    Crear Equipo
                </div>
            </div>
            <div class="col-3">
                <el-input v-model="buscar" placeholder="Buscar" class="w-100 br-20" size="small" />
            </div>
        </div>
        <div class="row mx-0 pt-3">
            <div v-for="(team, t) in filtroTeams" :key="t" class="cr-pointer" @click="verEquipo(team.id)">
                <div class="card-equipo bg-white px-3 py-2 mx-2 my-2">
                    <p class="text-general f-16 f-500">
                        {{ team.nombre }}
                    </p>
                    <p class="text-general f-16 my-2 nombre">
                        {{ team.descripcion }}
                    </p>
                    <template v-if="team.tiendas.length <= 6">
                        <div class="row mx-0 justify-content-arround">
                            <el-tooltip v-for="(tienda, ti) in team.tiendas" :key="ti" placement="bottom" :content="tienda.propietario_nombre" effect="light">
                                <img :src="tienda.imagen_mini" width="38" height="38" class="obj-cover border rounded-circle mr-1" />
                            </el-tooltip>
                        </div>
                    </template>
                    <template v-else>
                        <div class="row mx-0 justify-content-arround">
                            <el-tooltip v-for="(tienda, ti) in team.tiendas.slice(0,5)" :key="ti" placement="bottom" :content="tienda.propietario_nombre" effect="light">
                                <img :src="tienda.imagen_mini" width="38" height="38" class="obj-cover border rounded-circle mr-1" />
                            </el-tooltip>
                            <div class="rounded-circle bg-general3 d-middle-center text-white" style="width:38px;height:38px;">
                                +{{ team.tiendas.length - 5 }}
                            </div>
                        </div>
                    </template>
                    <div class="row mx-0 mt-3">
                        <div class="col px-0">
                            <div class="row mx-0">
                                <i class="icon-comision f-15 text-general" />
                                <div class="col px-0 pl-2">
                                    <p class="text-general f-16">
                                        {{ separadorNumero(team.ventas) }}
                                    </p>
                                    <p class="text-general f-12">
                                        Ventas último mes
                                    </p>
                                </div>
                            </div>
                            <div class="row mx-0 mt-2">
                                <div class="col-auto pl-0 pr-0">
                                    <i class="icon-package-variant-closed f-20 text-general" />
                                </div>
                                <div class="col px-0">
                                    <p class="text-general f-16">
                                        {{ separadorNumero(team.inventario) }}
                                    </p>
                                    <p class="text-general f-12">
                                        Valor del inventario
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 px-0 text-center">
                            <i class="icon-cliente f-20 text-general" />
                            <span class="text-general f-600 f-16">{{ agregarSeparadoresNumero(team.clientes) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-equipo ref="modalCrearEquipo" @actualizar="listarTeams" />
    </section>
</template>

<script>
import Teams from "~/services/teams";
import { mapGetters } from 'vuex'
export default {
    components: {
        modalCrearEquipo: () => import('./partials/modalCrearEquipo')
    },
    data(){
        return {
            buscar: '',
            teams: []
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
        filtroTeams(){
            return this.teams.filter(el =>
                !this.buscar
                || el.nombre.toLowerCase().includes(this.buscar.toLowerCase())
            )
        }
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_moneda === null){
                    setTimeout(async() => {
                        await this.listarTeams()
                    }, 1000);
                }else{
                    await this.listarTeams()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async listarTeams(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                }
                const { data } = await Teams.listarTeams(params)

                this.teams = data.teams
            } catch (error){
                this.error_catch(error)
            }
        },
        crearEquipo(){
            this.$refs.modalCrearEquipo.toggle();
        },
        verEquipo(id){
            this.$router.push({ name: 'admin.equipos.ver', params: { id_equipo: id} })
        },

    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
}
.h-32{
    height: 32px;
}
.card-equipo{
    width: 314px;
    min-height: 268px;
    border-radius: 4px;
    box-shadow: 0px 3px 4px #00000014;
}
</style>
