import axios from 'axios'
const api = "teams"

const teams = {
    listarTeams: params => axios(`${api}/team`, { params }),
    infoTeam: (idTeam, data) => axios(`${api}/team/${idTeam}`, data),
    listaLeecheros: params => axios(`${api}/lista-leecheros`, { params }),
    listaAdministradores: params => axios(`${api}/lista-administradores`, { params }),
    crearTeam: data => axios.post(`${api}/team`, data),
    editarTeam: (idTeam, data) => axios.put(`${api}/team/${idTeam}`, data),
    editarTeamUsers: (idTeam, data) => axios.put(`${api}/team-users/${idTeam}`, data),
    deleteTeam: idTeam => axios.delete(`${api}/team/${idTeam}`)
};
export default teams
